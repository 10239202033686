@import '../../../styles/_globalVariables.scss';

.noWrap {
  white-space: nowrap;
  position: relative;
  .newFlag {
    position: absolute;
    top: -4px;
    right: -12px;
    background: #10A26F;
    border-radius: 4px;
    padding: 2px 6px;
    font-weight: 400;
    font-size: 12px;
     line-height: 16px; 
    color: #fff;
    height: 20px;
    display: flex;
    align-items: center;
    min-width: 36px;
    justify-content: center;
    z-index: 1;
    [dir='rtl'] & {
      right: inherit;
      top:-4px;
      left: -17px;
    }
  }
}

.navbar {
  display: block;
  z-index: 99;

  &__control {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    border-radius: 8px;
    background-color: #f9f5fe;
    width: 36px;
    height: 32px;
    cursor: pointer;
    padding: 0px;
    z-index: 1;
  }

  @include for-size(tablet-up) {
    order: 2;
  }

  @media only screen and (max-width: 991px) {
    .crossicon {
      background-color: #fff;
      padding: 16px 29px;
      z-index: 1;
      position: absolute;
      width: 100%;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      left: 0;
      top: 0;
      [dir='rtl'] & {
        right: 0;
      }
    }
  }

  @media (min-width: 768px) and (max-width: 991px) {
    .crossicon {
      &::before {
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 60px;
        background-color: #fff;
      }
    }
  }

  @media screen and (max-width: 767px) {
    .crossicon {
      background-color: #fff;
      z-index: 1;
      width: 303px;
    }
  }
  

  &__content {
    .desktopview {
      display: flex;
      align-items: center;
      gap: 32px;
    }

    @include for-size(tablet-phone) {
      position: fixed;
      left: 0;
      top: 0;
      grid-template-columns: repeat(1, 1fr);
      align-content: start;
      justify-content: start;
      width: 303px;
      height: 100vh;
      padding: 64px 0 0px 0px ;
      background-color: #fff;
      overflow-y: auto;
      box-shadow: 0 5px 30px #ddd;
          &:after{
            content: '';
            position: fixed;
            top:0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100vh;
            background: rgb(0 0 0 / 62%);
          
          }
    }   
    

    &.hidden {
      transform: translateX(calc(-100% - 100px));
      opacity: 0;
    }

    @media screen and (max-width: 991px) {
      .mobilemenu {
        &_menuItem {
          h4 {
            font-size: 12px;
            text-transform: uppercase;
            font-weight: 500;
            margin-bottom: 8px;
            line-height: 24px;
            padding: 0;
            color: $color-gray;
          }
          ul {
            list-style: none;
            margin-bottom: 0;
            .arrowItem{
              svg{
                margin-right: 0;
              }
            }
            svg {
              min-width: 16px;
              min-height: 16px;
              max-width: 16px;
              max-height: 16px;
              margin-right: 16px;
              &:last-child {
                [dir='rtl'] & {
                  transform: rotate(180deg);
                }
              }
              [dir='rtl'] & {
                margin-left: 16px;
                margin-right: 0;
              }
            }
            .sidebarLink {
              display: flex;
              align-items: center;
              color: $heading-color;
              position: relative;
              width: 100%;
              ~ .submenu {
                height: 0;
                transition: all 0.3s;
                overflow: hidden;
                a {
                  font-weight: 500;
                  font-size: 16px;
                  color: $color-gray !important;
                  margin-top: 32px;
                }
              }
              &.active {
                &:before {
                  content: '';
                  height: 40px;
                  background-color: #f5e8fd;
                  border-radius: 0 12px 12px 0;
                  position: absolute;
                  top: -9px;
                  left: -24px;
                  z-index: -1;
                  @media screen and (min-width: 768px) and (max-width: 991px) {
                    width: 112%;
                  }
                }
                ~ .submenu {
                  height: auto;
                  width: 100%;
                }
                &.noTransform {
                  & svg {
                    &:last-child {
                      transform: none;
                      [dir='rtl'] & {
                        transform: rotate(180deg);
                      }
                    }
                  }
                }
                & svg {
                  &:last-child {
                    transform: rotate(90deg);
                  }
                }
              }
              span {
                font-weight: 500;
                font-size: 16px;
              }
             
              .arrowItem {
                margin-left: auto;
                display: flex;
                [dir='rtl'] & {
                  margin-right: auto;
                  margin-left: 0;
                }
                & svg {
                  margin: 0 !important;
                }
              }
            }
            li {
              padding: 16px 0px;
              display: flex;
              align-items: center;
              &.sidebarItem{
                align-items: flex-start;
                margin: 16px 0;
                padding: 0;
                grid-gap: 12px;
                position: relative;
              }
              .smallDetails{
                font-weight: 400;
                font-size: 13px;
                line-height: 16px;
                color: #737373;
              }

              &.mLogOut{
                border-top:1px solid #E5E5E6;
                margin-top: 9px;
                padding-bottom: 21px;
                .logOut {
                  width: 100%;              
                  span {
                    display: block;
                    text-align: center;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    color: #737373;
                    width: 100%;
                  }
                }
                
              }
              .addProperty {
                box-sizing: border-box;
                flex: 1;
              }
              .propBtns {
                border-radius: 12px;
                display: flex;
                background: #6f10a2;
                flex: 1;
                padding: 12px 16px;
                color: #fff;
                justify-content: space-between;
                align-items: center;
                font-size: 16px;
                font-weight: 700;
                line-height: 24px;
                height: 60px;
                & div {
                  &:first-of-type {
                    display: flex;
                    min-width: 32px;
                    max-width: 32px;
                  }
                }
                svg {
                  min-width: 32px;
                  min-height: 32px;
                  max-width: 62px;
                  max-height: 62px;
                  margin-right: -16px;
                  display: flex;
                  [dir='rtl'] & {
                    transform: rotate(0);
                  }
                }
              }
              & span {
                font-size: 16px;
                font-weight: 500;
                color: #510c76;
              }
            }
          }
          &:not(:last-child) {
            border-bottom: 1px solid #E5E5E6;
            padding-bottom: 8px;
            margin-bottom: 24px;
          }

          &.mMenuContact{
            li{
              span{
                font-weight: 600;
                font-size: 18px;
                line-height: 24px;
              }
            }
          }
        }

        .arrowItem {
          margin-left: auto;
          margin-right: 0px;
          position: absolute;
          top: 3px;
          right: 0px;
          html[dir='rtl'] & {
            left: -5px;
            right:inherit;
            width: 24px;
          }
        }
      }
    }
    @media (max-width: 767px) {
      .mobilemenu {
        &_menuItem {
          ul {
            a {
              span {
                ~ svg {
                  right: 10px;
                }
              }
              &.active {
                &::before {
                  width: calc(100% + 26px);
                }
              }
            }
          }
        }
      }
    }
  }
  @include for-size(tablet-up){
    .desktopview{
      gap: 8px;
    }
  }

  &__link {
    position: relative;
    display: block;
    font-size: $font16;
    font-weight: $font-semi-bold;
    line-height: $lineH24;
    cursor: pointer;
    padding: $pad8 $pad0;
    text-align: center;
    color: #6f10a2;
    transition: .3s ease;
    @media screen and (max-width: 979px) {
      border-radius: 4px;
    }
    @include for-size(tablet-up){
      color: $Cool-Grey-900 !important;
      font-weight: $default-font-weight;
      padding: $pad8 $pad16;

      &:hover{
        background: $revamp-color-one;
        color: $violet !important;
        border-radius: 8px;
      }

    }

    &::before {
      content: '';
      display: none;
      position: absolute;
      height: 2px;
      width: 100%;
      background: transparent;
      bottom: 0;
      left: 0;

      @media screen and (max-width: 979px) {
        content: none;
      }
    }
    
    &.has_dropdown {
      &:after {
        content: '';
        display: none;
        position: absolute;
        width: 8px;
        height: 8px;
        border: 1px solid;
        border-color: currentColor currentColor transparent transparent;
        right: 4px;
        transform: rotate(135deg) scale(1);
        top: 19px;
        transition: all 0.3s ease-in-out;

        @media screen and (max-width: 979px) {
          position: relative;
          display: inline-block;
          transform: rotate(45deg) scale(1);
          margin-inline-start: 16px;
          top: 0;
          width: 6px;
          height: 6px;
        }
      }

      &.active {
        &::after {
          top: 23px;
          transform: rotate(135deg) scale(-1);
          @media screen and (max-width: 979px) {
            transform: rotate(45deg) scale(1);
          }
        }
      }
    }
  }
  &__lightheader {
    color: $color-grey-600;
    &:hover {
      color: $violet;
    }
    &:hover,
    &.active {
      &::before {
        background: linear-gradient(270deg, #6f10a2 -2.06%, #dfa138 100%);
        display: none;
      }

      @media screen and (max-width: 979px) {
        background-color: #6f10a2;
        color: #6f10a2;
      }
    }
  }
  &__darkheader {
    color: #fff;
  }

  .dropdownParent {
    position: relative;
  }
  .cursor_pointer {
    cursor: pointer;
  }
  &__dropdown {
    position: absolute;
    width: max-content;
    background-color: #fff;
    padding: 1rem;
    display: none;
    left: 0;
    top: 100px;
    z-index: 101;
    box-shadow: 0px 8px 30px rgba(161, 168, 191, 0.4), 0px 0px 2px rgba(0, 0, 0, 0.2);
    border-radius: 16px;

    &__dropdownitem {
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 1rem;
      border-radius: 12px;
      &__text {
        padding-left: 20px;
        color: #111827;
        font-weight: 600;
        p {
          color: #6b7280;
          font-weight: 400;
        }
      }
      &:hover {
        background-color: #f2f2f3;
      }
    }

    &.open {
      display: block;
    }

    &.home:not(.sticky) {
      top: 100px;
      @media screen and (max-width: 767px) {
        top: 0;
      }
    }

    @media screen and (max-width: 979px) {
      top: 0;
      left: 50vw;
      width: 50vw;
      height: 100vh;
      overflow: hidden;
      z-index: 101;
    }
    @media screen and (max-width: 640px) {
      left: 0;
      width: 100%;
      max-width: 100vw;
      height: 100vh;
      overflow: hidden;
      z-index: 101;
    }

    &.sticky {
      top: 100px;
    }
  }

  .dropdown {
    position: relative;
    display: inline-block;

    &_innerrow {
      display: inline-flex;
      align-items: center;
      height: 100%;
      max-width: 100%;
      background-color: #f2f2f3;
      border-radius: 16px;
    }
    &_leftsec {
      min-width: 367px;
      max-width: 367px;
      background-color: #fff;
      padding: 1rem;
      box-shadow: 0px 8px 30px rgba(161, 168, 191, 0.4), 0px 0px 2px rgba(0, 0, 0, 0.2);
      border-radius: 16px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      a {
        border-radius: 12px;
        position: relative;
        span {
          ~ svg {
            position: absolute;
            display: none;
            right: 15px;
          }
        }
        &:hover {
          span {
            ~ svg {
              display: block;
            }
          }
        }
      }
    }

    &_rightsec {
      max-width: calc(100% - 367px);
      flex: 0 0 calc(100% - 367px);
      overflow-x: auto;
    }
  }

  .dropdown_content {
    display: none;
    position: absolute;
    z-index: 1;
    width: auto;
    left: 0;
    top: 52px;
    border-radius: 16px;
    &.megamenuopen {
      width: calc(100vw - 82px);

      .dropdown_rightsec {
        display: block;
        padding: 0 15px;
      }
    }
    &.megamenusale {
      width: 1330px;
      @media (max-width: 1400px) {
        width: calc(100vw - 70px);
      }
    }
  }
  .dropdown_autiondrop {
    left: -85px;
    top: 75px;
    span {
      font-weight: 500 !important;
      color: $heading-color;
    }
  }
  .dropdown_finddrop {
    left: -65px;
  }

  .dropdown_content a {
    color: black;
    padding: 0;
    text-decoration: none;
    display: block;
  }

  .dropdown_content a:hover {
    background-color: #ddd;
  }

  .headerNav .dropdown_content {
    display: block !important;
  }

  &__link.active ~ &__dropdown {
    display: block;
  }
}

[dir='rtl'] {
  .navbar {
    &__content {
      @media (min-width: 768px) and (max-width: 991px) {
        left: inherit;
        right: 0px;
      }
      @media (max-width: 991px) {
        .mobilemenu {
          &_menuItem {
            ul {
              a {
                span {
                  ~ svg {
                    top: 3px;
                    left: 55px;
                    right: auto;
                    margin-left: 0;
                    transform: rotate(180deg);
                  }
                }
                &.active {
                  &:before {
                    left: auto;
                    right: -24px;
                    border-radius: 12px 0 0 12px;
                  }
                  span {
                    ~ svg {
                      transform: rotate(90deg);
                    }
                  }
                }
                &.logOut {
                  span {
                    text-align: right;
                  }
                }
              }
              svg {
                margin-left: 16px;
                margin-right: 0;
              }
              li {
                .propBtns {
                  padding: 8px 32px 8px 12px;
                  svg {
                    margin-right: auto;
                    margin-left: -20px;
                  }
                }
              }
            }
          }
        }
      }
      @media (max-width: 767px) {
        .mobilemenu {
          &_menuItem {
            &:not(:last-child) {
              &:after {
                right: 24px;
                left: auto;
              }
            }
            ul {
              a {
                span {
                  ~ svg {
                    left: 15px;
                    right: auto;
                  }
                }
                &.logOut {
                  span {
                    text-align: center;
                  }
                }
              }
            }
          }
        }
      }
    }
    &__dropdown {
      &__dropdownitem {
        &__text {
          padding-left: 0;
          padding-right: 16px;
          p {
            color: #6b7280;
            font-weight: 400;
          }
        }
      }
    }
    .dropdown_finddrop {
      left: inherit;
      right: -91px;
    }
    .dropdown_autiondrop {
      left: inherit;
      right: -105px;
    }
    .dropdown {
      &_leftsec {
        a {
          span {
            ~ svg {
              right: auto;
              left: 15px;
              transform: rotate(180deg);
            }
          }
        }
      }
    }
  }
}

.homehamburger {
  position: relative;
  display: block;
  width: 32px;
  height: 2px;
  border-radius: 2px;
  background-color: #ffff;
  transition: transform 0.3s ease-in-out;

  &::before,
  &::after {
    content: '';
    position: absolute;
    display: block;
    height: 2px;
    background-color: #fff;
    transition: transform 0.3s ease-in-out;
    border-radius: 2px;
  }

  &::before {
    width: 100%;
    background-color: #fff;

    top: -8px;
  }

  &::after {
    width: 60%;

    bottom: -8px;
  }

  &.open {
    background-color: transparent;

    &::before,
    &::after {
      width: 100%;
      background-color: #510c76;
    }

    &::before {
      transform: rotate(45deg);
      top: 50%;
    }
    &::after {
      transform: rotate(-45deg);
      top: 50%;
    }
  }
}

.hamburger {
  position: relative;
  width: 18px;
  height: 2px;
  border-radius: 2px;
  background-color: #510c76;

  &:before,
  &:after {
    content: '';
    position: absolute;
    display: block;
    height: 2px;
    border-radius: 12px;
    background-color: #510c76;
    transition: transform .3s ease-in-out;
  }

  &:before {
    width: 100%;
    top: -5px;
    left: 0px;
  }

  &:after {
    width: 9px;
    bottom: -5px;
    left: 0px;
  }
}

.lightnavbtn {
  background-color: #4c4c4c;
  &::before,
  &::after {
    background-color: #4c4c4c;
  }
}

.cross {
  position: relative;
  display: block;
  width: 32px;
  height: 32px;
  border-radius: 2px;
  transition: transform 0.3s ease-in-out;

  &::before,
  &::after {
    content: '';
    position: absolute;
    display: block;
    height: 2px;
    background-color: #510c76;
    transition: transform 0.3s ease-in-out;
    width: 100%;
  }

  &::before {
    transform: rotate(45deg);
    top: 50%;
  }
  &::after {
    transform: rotate(-45deg);
    top: 50%;
  }
}

html[dir='rtl'] {
  .navbar {
    &__content {
      @media screen and (max-width: 979px) {
        right: 0px;
        left: auto;
      }
      @media screen and (max-width: 767px) {
        right: 0;
        padding-right: 0;
      }
      &.hidden {
        transform: translateX(calc(100%));
        opacity: 0;
      }
    }
  }
  .has_dropdown {
    &::after {
      @media screen and (max-width: 979px) {
        transform: rotate(45deg) scale(-1);
      }
    }
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .navbar {
      &__content {
        gap: 10px;
      }
    }
    .has_dropdown {
      padding-inline-end: 0px;
    }
  }
}

.desktopview {
  align-items: center;
}
.has_dropdown {
  display: flex;
}

@media screen and (min-width: 979px) {
  .mobilemenu {
    display: none;
  }
}
@media screen and (max-width: 980px) {
  .desktopview {
    display: none;
  }
}
@media screen and (max-width: 979px) {
  .mobilemenu {
    display: block;
    padding: 0 24px;
    background: #fff;
    position: relative;
    z-index: 9;
  }
}

.alignCenter {
  display: flex;
  align-items: center;
}
.phone_number {
  color: #6B7280 !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 24px;
  margin-left: 0.5rem;
  html[dir='rtl'] & {
    margin-left: 0px;
    margin-right: 0.5rem;
  }
}
.contactus {
  margin-top: 0.5rem;
}
.w_100 {
  width: 100% !important;
}
.d_flex { display: flex; align-items: center; }
.rotateImg {
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
}

.mr_16 {
  margin-right: 16px;
  [dir='rtl'] & {
    margin-right: 0px;
    margin-left: 16px;
  }
}
.auctionSelected {
  position: relative;
  color: $violet;
}
.titleSbar{
  position: relative;
.newAuction{
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #FFFFFF;
  background: #10A26F;
  border: 1px solid #10A26F;
  border-radius: 4px;
  position: absolute;
  top: -12px;
  right: -38px;
  padding: 0 6px;
  height: 20px;
  display: flex;
  align-items: center;
  html[dir='rtl'] & {
    left: -38px;
    right:inherit;
  }
}
}
.liveLabelAnimate{
  position: absolute;
  top: -14px;
  right: -12px;
  z-index: 3;
  width: 32px;
  @include for-size(tablet-up){
    top: -9px;
    right: 0px;  
  }
  html[dir='rtl'] & {
    right: auto;
    left: -16px;
    top: -12px;
    width: 60px;
    @include for-size(tablet-up){
      top: -9px;
      right: auto; 
      left: 0;
    }
  }
}

.upcomingPulse{
  position: absolute;
  top: 10px;
  right: -6px;
  z-index: 3;
  width: 32px;
  html[dir='rtl'] & {
    right: auto;
    left: -40px;
    top: 10px;
    width: 60px;
  }
}
.PulseAnime{
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 8px;
    height: 8px;
    background-color: rgba(237, 145, 7, 0.9);
    text-align: center;
    line-height: 100px;
    border-radius: 100%;
    font-size: 1.3rem;
    right: 4px;
    &::after{
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 8px;
    height: 8px;
    border: 1px solid #e96c06;
    border-radius: 50%;
    z-index: -1;
    animation: live 1.2s ease-in-out infinite;
  }
  &::before{
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 8px;
    height: 8px;
    border: 1px solid #e96c06;
    border-radius: 50%;
    z-index: -1;
    animation: live 1.2s ease-in-out infinite;
  }

}

@keyframes live {
    0% {
        transform: scale(1, 1);
        opacity: 1;
    }

    100% {
        transform: scale(2.2, 2.2);
        opacity: 0;
    }
}

@include for-size(tablet-up) {
  .isNotMobile {
    display: none;
  }
}
@include for-size(phone-only) {
  .isNotDesktop {
      display: none;
  }
}
